/**
 * @file
 * @author Alwyn Tan
 */

import { motion } from 'framer-motion'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import styled from 'styled-components'
import isEmail from 'validator/lib/isEmail'
import Button from '#/components/atoms/Button'
import Input from '#/components/atoms/Input'
import Text from '#/components/atoms/Text'
import useRequestLogin from '#/query/auth/useRequestLogin'

const Container = styled(motion.form)`
  margin-top: 4px;
`

type FormValues = { email: string }

type Props = { onComplete: (email: string) => void }

const RequestLoginForm = ({ onComplete }: Props) => {
  const { control, handleSubmit, formState, setError } = useForm<FormValues>({
    mode: 'onChange',
  })
  const requestLoginMutation = useRequestLogin()

  const onSubmit: SubmitHandler<FormValues> = async ({ email }) => {
    requestLoginMutation.mutate(email, {
      onError: (e: any) => {
        setError('email', { type: 'custom', message: e?.message })
      },
      onSuccess: () => {
        onComplete(email)
      },
    })
  }

  return (
    <Container
      initial={{ opacity: 0, x: -100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -100 }}
      transition={{ type: 'spring', duration: 0.5, bounce: 0.1 }}
    >
      <Text type="h4">Welcome to Disco</Text>
      <Text style={{ margin: '4px 0 16px 0', opacity: 0.5 }}>
        Please login with your email
      </Text>
      <Input
        name="email"
        control={control}
        placeholder="Enter email"
        suppressRequiredError
        suppressValidateError
        inputProps={{ type: 'email', autoFocus: true }}
        rules={{
          required: true,
          validate: value => isEmail(value) || 'Enter a valid email',
        }}
      />
      <Button
        disabled={!formState.isValid}
        loading={requestLoginMutation.isLoading}
        size="medium"
        onClick={handleSubmit(onSubmit)}
        style={{ marginTop: 10 }}
      >
        Next
      </Button>
    </Container>
  )
}

export default RequestLoginForm
