/**
 * @file
 * @author Alwyn Tan
 */

import dayjs from 'dayjs'
import { AnimatePresence, motion } from 'framer-motion'
import { QRCode } from 'jsqr'
import React from 'react'
import { CheckCircle, X, XCircle } from 'react-feather'
import styled from 'styled-components'
import validator from 'validator'
import Button from '#/components/atoms/Button'
import Spinner from '#/components/atoms/Spinner'
import Text from '#/components/atoms/Text'
import { Green, Red } from '#/constants/colors'
import useCheckinPass from '#/query/pass/useCheckinPass'
import useGetPass from '#/query/pass/useGetPass'

const Container = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #00000080;
`

const InnerContainer = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalContainer = styled.div`
  padding: 24px 40px;
  background: #00000080;
  backdrop-filter: blur(12px);
  border-radius: 12px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 280px;
`

const XButton = styled.div`
  position: absolute;
  height: 42px;
  width: 42px;
  bottom: 60px;
  border-radius: 21px;
  background: #000000bf;
  backdrop-filter: blur(12px);
  display: flex;
  justify-content: center;
  align-items: center;
`

type Props = {
  code?: QRCode
  onClose: () => void
}

const ScanResult = ({ code, onClose }: Props) => {
  const isValidCode = validator.isMongoId(code?.data || '')
  const { data: pass, isFetching } = useGetPass(
    isValidCode ? code?.data || '' : ''
  )
  const checkinPassMutation = useCheckinPass()

  const handleCheckin = async () => {
    await checkinPassMutation.mutateAsync(code?.data || '')
  }

  const renderValid = () => (
    <ModalContainer>
      <Text
        type="h4"
        style={{
          color: Green,
          marginBottom: 24,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        Pass is Valid <CheckCircle style={{ marginLeft: 6 }} />
      </Text>
      <Text type="button2">{(pass?.user as User)?.name}</Text>
      <Text type="p2" style={{ padding: '16px 0' }}>
        for
      </Text>
      <Text type="button2">{pass?.title}</Text>
      <Text type="p2" style={{ padding: '6px 0' }}>
        {dayjs(pass?.activeDate).tz().format('MMM D, YYYY')}
      </Text>
      <Text type="p2">
        {dayjs(pass?.activeDate).tz().format('h:mma')} -{' '}
        {dayjs(pass?.expiryDate).tz().format('h:mma')}
      </Text>
      <Button
        disabled={!!pass?.checkedIn?.date}
        onClick={handleCheckin}
        style={{ marginTop: 24 }}
      >
        {pass?.checkedIn?.date ? 'User checked-in' : 'Check-in User'}
      </Button>
      {!!pass?.checkedIn?.date && (
        <Text type="p2" style={{ opacity: 0.5, paddingTop: 6 }}>
          Checked-in {dayjs(pass?.checkedIn?.date).tz().format('MMM D, h:mma')}
        </Text>
      )}
    </ModalContainer>
  )

  const renderInvalid = (reason?: string) => (
    <ModalContainer>
      <Text
        type="h4"
        style={{ color: Red, display: 'flex', alignItems: 'center' }}
      >
        Invalid Pass <XCircle style={{ marginLeft: 6 }} />
      </Text>
      {!!reason && <Text style={{ paddingTop: 24 }}>{reason}</Text>}
    </ModalContainer>
  )

  const renderLoading = () => (
    <ModalContainer>
      <Text type="h4" style={{ display: 'flex', alignItems: 'center' }}>
        <Spinner style={{ marginRight: 10 }} />
        Loading...
      </Text>
    </ModalContainer>
  )

  const render = () => {
    if (!isValidCode) return renderInvalid('Not a Disco Link')

    if (isFetching) return renderLoading()

    if (pass) {
      const passExpired = dayjs(pass?.expiryDate).tz().isBefore(dayjs())

      if (passExpired)
        return renderInvalid(
          `Pass Expired on ${dayjs(pass?.expiryDate)
            .tz()
            .format('MMM D, h:mma')}`
        )

      return renderValid()
    }

    return null
  }

  return (
    <AnimatePresence>
      {code && (
        <Container
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ type: 'spring', duration: 0.5, bounce: 0.1 }}
        >
          <InnerContainer
            initial={{ y: '100%' }}
            animate={{ y: 0 }}
            exit={{ y: '100%' }}
            transition={{ type: 'spring', duration: 0.5, bounce: 0.1 }}
          >
            {render()}
            <XButton onClick={onClose}>
              <X color="white" />
            </XButton>
          </InnerContainer>
        </Container>
      )}
    </AnimatePresence>
  )
}

export default ScanResult
