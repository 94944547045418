/**
 * @file Query to get stls
 * @author Alwyn Tan
 */

import { useInfiniteQuery } from '@tanstack/react-query'
import axios from 'axios'
import { stlKeys } from '#/query-key-factory'

type GetSTLsResponse = {
  stls: STL[]
  canLoadMore: boolean
}

type GetSTLParams = {
  partner: string
  nextAvailableEndDate?: string
  sortBy?: string[]
}

type PageParam = {
  prevID: string
}

const getSTLs = async (params: GetSTLParams, pageParam: PageParam) =>
  (
    await axios.get<GetSTLsResponse>('/stl/v2', {
      params: { ...pageParam, ...params },
    })
  ).data

const useGetSTLs = (params: GetSTLParams) =>
  useInfiniteQuery(
    stlKeys.list(params),
    async ({ pageParam }) => getSTLs(params, pageParam),
    {
      // @ts-ignore
      select: data =>
        // @ts-ignore
        data?.pages?.reduce((acc, curr) => [...acc, ...curr.stls], []),
      getNextPageParam: prevPage => {
        if (prevPage.canLoadMore) {
          const prevSTL = prevPage.stls[(prevPage?.stls.length || 0) - 1]
          return { prevID: prevSTL?.id }
        }
        return undefined
      },
    }
  )

export default useGetSTLs
