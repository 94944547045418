/**
 * @file Profile Picture Component
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'

const Container = styled.div<{ size: number }>`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  border-radius: ${({ size }) => size / 2}px;
  overflow: hidden;
  background-color: #ffffff66;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`

const Initials = styled.p`
  color: white;
  font-family: Inter;
  font-weight: 600;
`

type Props = {
  style?: React.CSSProperties
  onClick?: () => void
  user: User
  size?: number
}

const ProfilePicture = ({
  style,
  onClick = () => {},
  user,
  size = 36,
}: Props) => {
  const createInitials = () =>
    user?.name
      ?.match(/(\b\S)?/g)
      ?.join('')
      ?.match(/(^\S|\S$)?/g)
      ?.join('')
      .toUpperCase()

  return (
    <Container style={style} size={size} onClick={onClick}>
      {user?.picture ? (
        <Image src={user.picture} />
      ) : (
        <Initials>{createInitials()}</Initials>
      )}
    </Container>
  )
}

export default ProfilePicture
