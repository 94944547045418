/**
 * @file Skip the line guest list page
 * @author Alwyn Tan
 */

import React, { useLayoutEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import InfiniteScroll from '#/components/molecules/InfiniteScroll'
import { useHeader } from '#/contexts/header-context'
import useGetPasses from '#/query/pass/useGetPasses'
import useGetSTL from '#/query/stl/useGetSTL'
import STLGuestRowItem from './STLGuestRowItem'

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8px;
`

const STLGuestList = () => {
  const { stlID } = useParams()
  const { setHeaderTitle, setHeaderImage, resetHeader } = useHeader()
  const { data: stl } = useGetSTL(stlID || '')

  useLayoutEffect(() => {
    setHeaderImage(stl?.poster || '')
    setHeaderTitle(stl?.title || '')
    return resetHeader
  }, [resetHeader, setHeaderImage, setHeaderTitle, stl?.poster, stl?.title])

  return (
    <Container>
      <InfiniteScroll
        infiniteQueryHook={useGetPasses}
        infiniteQueryHookParams={[
          { stlID, populate: ['user'], sortBy: ['+name'] },
        ]}
        style={{ paddingLeft: 16, paddingRight: 16 }}
      >
        {pass => <STLGuestRowItem pass={pass} />}
      </InfiniteScroll>
    </Container>
  )
}

export default STLGuestList
