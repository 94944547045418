/**
 * @file Implementation of a swipable tab navigator with Swiper Js
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .swiper {
    width: 100%;
    height: 100%;
    padding-top: 6px;
  }

  .swiper-pagination-bullet {
    padding: 6.5px 20px;
    border-radius: 100px;
    height: unset;
    width: unset;
    color: white;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 0.95rem;
    opacity: 1;
    background-color: transparent;
    margin: 0 !important;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: auto;
  }

  .swiper-pagination-bullet-active {
    background-color: #ffffff1a;
  }
`

const SwiperTabsContainer = styled.div`
  position: sticky;
  top: 0px !important;
  bottom: unset !important;
  z-index: 2;
  padding-bottom: 8px;
  display: flex;
  justify-content: center;
  width: 100%;
`

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow-y: auto;
`

type Props = {
  children: React.ReactNode[]
  titles?: string[]
  tabStyle?: React.CSSProperties
}

const TabNav = ({ children, titles = [], tabStyle = {} }: Props) => {
  const pagination = {
    el: '#swiper-pagination-container',
    clickable: true,
    renderBullet(index: number, className: string) {
      return `<span class=${className}>${titles?.[index] || index}</span>`
    },
  }

  const renderSlides = () =>
    children.map((child, i) => (
      <SwiperSlide key={titles?.[i] || i}>
        <Page>{child}</Page>
      </SwiperSlide>
    ))

  return (
    <Container>
      <SwiperTabsContainer>
        <div id="swiper-pagination-container" style={tabStyle} />
      </SwiperTabsContainer>
      <Swiper
        pagination={pagination}
        modules={[Pagination]}
        className="mySwiper"
      >
        {renderSlides()}
      </Swiper>
    </Container>
  )
}

export default TabNav
