/**
 * @file QR Scanner component
 * @author Alwyn Tan
 */

import { QRCode } from 'jsqr'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useHeader } from '#/contexts/header-context'
import ScanResult from './ScanResult'

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  > video {
    width: 100%;
    height: 100%;
  }
`

const Frame = styled.div`
  position: absolute;

  --b: 5px; /* thickness of the border */
  --c: #f4c20c; /* color of the border */
  --w: 35%; /* width of border */
  --r: 12%; /* radius */

  padding: var(--b); /* space for the border */

  /*Irrelevant code*/
  width: 200px;
  height: 100px;
  box-sizing: border-box;
  display: inline-flex;
  font-size: 30px;
  justify-content: center;
  align-items: center;
  text-align: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  ::before {
    content: '';
    position: absolute;
    inset: 0;
    background: var(--c, red);
    padding: var(--b);
    border-radius: var(--r);
    -webkit-mask: linear-gradient(0deg, #000 calc(2 * var(--b)), #0000 0) 50%
        var(--b) / calc(100% - 2 * var(--w)) 100% repeat-y,
      linear-gradient(-90deg, #000 calc(2 * var(--b)), #0000 0) var(--b) 50%/100%
        calc(100% - 2 * var(--w)) repeat-x,
      linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
`

const FRAME_PADDING = 20

const qrWorkerInstance = new Worker(
  new URL('#/worker/qrWorker.ts', import.meta.url)
)
let workerReady = true

const QRScanner = () => {
  const streamRef = useRef<MediaStream | null>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(document.createElement('canvas'))
  const [scaleFactor, setScaleFactor] = useState(0)
  const [videoReady, setVideoReady] = useState(false)
  const [code, setCode] = useState<QRCode>()
  const { setHeaderStyle, resetHeader } = useHeader()

  // setup header
  useLayoutEffect(() => {
    setHeaderStyle({ position: 'absolute', background: 'transparent' })
    return resetHeader
  }, [resetHeader, setHeaderStyle])

  useEffect(() => {
    qrWorkerInstance.onmessage = message => {
      if (message?.data) setCode(message.data)
      workerReady = true
    }
  }, [])

  useEffect(() => {
    if (videoReady && containerRef.current && videoRef.current) {
      const rect = containerRef.current.getBoundingClientRect()
      setScaleFactor(
        Math.min(
          rect.width / videoRef.current.videoWidth,
          rect.height / videoRef.current.videoHeight
        )
      )
    }
  }, [videoReady])

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({
        video: {
          facingMode: 'environment',
          aspectRatio: 1.777777778,
        },
        audio: false,
      })
      .then(stream => {
        streamRef.current = stream
        if (videoRef.current) {
          const tick = () => {
            if (
              containerRef.current &&
              videoRef.current &&
              canvasRef.current &&
              videoRef.current.readyState === videoRef.current.HAVE_ENOUGH_DATA
            ) {
              setVideoReady(true)
              canvasRef.current.width = videoRef.current.videoWidth
              canvasRef.current.height = videoRef.current.videoHeight

              const canvas = canvasRef.current.getContext('2d')

              if (canvas) {
                canvas.drawImage(
                  videoRef.current,
                  0,
                  0,
                  canvasRef.current.width,
                  canvasRef.current.height
                )
                const imageData = canvas.getImageData(
                  0,
                  0,
                  canvasRef.current.width,
                  canvasRef.current.height
                )

                if (workerReady) {
                  qrWorkerInstance.postMessage(imageData, [
                    imageData.data.buffer,
                  ])
                  workerReady = false
                }
              }
            }

            if (streamRef.current) requestAnimationFrame(tick)
          }

          // todo, not sure why 2 different streams are found
          videoRef.current.srcObject = stream
          videoRef.current.setAttribute('playsinline', 'true') // required to tell iOS safari we don't want fullscreen
          videoRef.current.play()
          requestAnimationFrame(tick)
        }
      })

    return () => {
      if (streamRef.current)
        streamRef.current.getTracks().forEach(track => track.stop())
    }
  }, [])

  return (
    <Container ref={containerRef}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video ref={videoRef} />
      {code && (
        <Frame
          style={{
            left:
              (containerRef.current?.clientWidth || 0) / 2 -
              ((videoRef.current?.videoWidth || 0) * scaleFactor) / 2 +
              code.location.topLeftCorner.x * scaleFactor -
              FRAME_PADDING / 2,
            top:
              (containerRef.current?.clientHeight || 0) / 2 -
              ((videoRef.current?.videoHeight || 0) * scaleFactor) / 2 +
              code.location.topLeftCorner.y * scaleFactor -
              FRAME_PADDING / 2,
            width:
              (code.location.bottomRightCorner.x -
                code.location.topLeftCorner.x) *
                scaleFactor +
              FRAME_PADDING,
            height:
              (code.location.bottomRightCorner.x -
                code.location.topLeftCorner.x) *
                scaleFactor +
              FRAME_PADDING, // use same as width to maintain a square
          }}
        />
      )}
      <ScanResult code={code} onClose={() => setCode(undefined)} />
    </Container>
  )
}

export default QRScanner
