/**
 * @file Login Hook for partners
 * @author Alwyn Tan
 */

import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import toast from 'react-hot-toast'
import { partnerKeys } from '#/query-key-factory'

type LoginMutationParams = {
  email: string
  code: string
}

type LoginResponseJSON = {
  partner: { id: string; fcmTokens?: string[] }
  accessToken: string
}

const postLogin = async ({ email, code }: LoginMutationParams) =>
  (
    await axios.post<LoginResponseJSON>(
      '/partner/user/login',
      { email, code },
      { withCredentials: true }
    )
  ).data

const useLogin = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ email, code }: LoginMutationParams) => {
      const { accessToken, partner } = await postLogin({ email, code })
      return { ...partner, accessToken }
    },
    {
      onMutate: () => {
        const toastID = toast.loading('Loading')
        return { toastID }
      },
      onError: (result, variables, context) => {
        toast.error('Error Requesting Login', { id: context?.toastID })
      },
      onSuccess: (partner, variables, context) => {
        axios.defaults.headers.common.Authorization = `Bearer ${partner.accessToken}`
        toast.success('Logged In!', { id: context?.toastID })
        queryClient.invalidateQueries(partnerKeys.me())
      },
    }
  )
}

export default useLogin
