/**
 * @file Skip the line list item (card)
 * @author Alwyn Tan
 */

import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Text from '#/components/atoms/Text'
import AvailableSTLCardDateRange from '#/components/organisms/STL/STLCardTimeRange'
import { DarkGray } from '#/constants/colors'

const Container = styled.div`
  height: 235px;
  width: 100%;
  max-width: 400px;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 27.6%,
      rgba(0, 0, 0, 0) 57.81%,
      rgba(0, 0, 0, 0.5) 81.25%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url(${({ poster }: { poster: string }) => poster}), ${DarkGray};
  background-size: cover;
  background-position: 50% 25%;
  background-repeat: no-repeat;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  margin-bottom: 10px;
`

const TextContainer = styled.div`
  position: absolute;
  left: 20px;
  bottom: 20px;
  right: 100px;
`

type Props = {
  stl: STL
}

const STLCard = ({ stl }: Props) => {
  const navigate = useNavigate()

  const handleContainerClick = () => {
    navigate(`/stl/${stl.id}`)
  }

  return (
    <Container poster={stl.poster} onClick={handleContainerClick}>
      <AvailableSTLCardDateRange dateRange={stl.dateRange} />
      <TextContainer>
        <Text type="h4">{stl.title}</Text>
      </TextContainer>
    </Container>
  )
}

export default STLCard
