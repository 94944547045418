/**
 * @file Checks in a user
 * @author Alwyn Tan
 */

import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { passKeys } from '#/query-key-factory'

type CheckinPassResponse = {
  pass: Pass
}

const postCheckinPass = async (id: string) =>
  (await axios.post<CheckinPassResponse>('/pass/v2/checkin', { id })).data

const useCheckinPass = () => {
  const queryClient = useQueryClient()

  return useMutation(async (id: string) => postCheckinPass(id), {
    onSuccess: (data, passID) => {
      queryClient.invalidateQueries(passKeys.detail(passID))
    },
  })
}

export default useCheckinPass
