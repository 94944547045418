/**
 * @file Hook to receive current user data from the server
 * @author Alwyn Tan
 */

import { QueryClient, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { partnerKeys } from '#/query-key-factory'

type CurrentPartnerResponseJSON = {
  partnerUser: PartnerUser
}

const getCurrentPartner = async () =>
  (await axios.get<CurrentPartnerResponseJSON>('/partner/user/me'))?.data

const useCurrentPartner = ({ retry = 3 } = {}) =>
  useQuery({
    queryKey: partnerKeys.me(),
    queryFn: async () => getCurrentPartner(),
    select: data => data?.partnerUser,
    retry,
    refetchOnWindowFocus: false,
  })

// to use in new react-router v6 api
const currentPartnerLoader = (queryClient: QueryClient) => async () =>
  queryClient.ensureQueryData({
    queryKey: partnerKeys.me(),
    queryFn: async () => {
      let res = {}
      try {
        res = await getCurrentPartner()
      } catch (e) {
        //
      }
      return res
    },
  })

export { currentPartnerLoader }
export default useCurrentPartner
