/**
 * @file Button Component
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import Spinner from '#/components/atoms/Spinner'
import Text from '#/components/atoms/Text'
import { Purple } from '#/constants/colors'

const StyledButton = styled.button`
  background-color: ${({ buttonType }: { buttonType: string }) =>
    buttonType === 'primary' ? Purple : '#ffffff1a'};
  border-radius: 100px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  padding: 0 16px;
  outline: none;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  > p {
    display: flex;
    align-items: center;
  }
`

const SmallButton = styled(StyledButton)`
  height: 40px;
`

const MediumButton = styled(StyledButton)`
  height: 48px;
  width: 100%;
`

const LargeButton = styled(StyledButton)`
  height: 62px;
  width: 100%;
`

type Props = {
  children: string | React.ReactNode
  disabled?: boolean
  loading?: boolean
  size?: 'small' | 'medium' | 'large'
  buttonType?: 'primary' | 'secondary'
  style?: React.CSSProperties
  onClick: (
    e: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>
  ) => void
}

const Button = ({
  children = '',
  disabled = false,
  loading = false,
  size = 'small',
  buttonType = 'primary',
  onClick,
  style,
}: Props) => {
  switch (size) {
    case 'small':
      return (
        <SmallButton
          style={style}
          disabled={disabled}
          onClick={onClick}
          buttonType={buttonType}
        >
          {loading ? <Spinner /> : <Text type="button2">{children}</Text>}
        </SmallButton>
      )

    case 'medium':
      return (
        <MediumButton
          style={style}
          disabled={disabled}
          onClick={onClick}
          buttonType={buttonType}
        >
          {loading ? <Spinner /> : <Text type="button2">{children}</Text>}
        </MediumButton>
      )

    case 'large':
      return (
        <LargeButton
          style={style}
          disabled={disabled}
          onClick={onClick}
          buttonType={buttonType}
        >
          {loading ? <Spinner /> : <Text type="button1">{children}</Text>}
        </LargeButton>
      )

    default:
      return null
  }
}

export default Button
