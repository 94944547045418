/**
 * @file Gets a user's pass
 * @author Alwyn Tan
 */

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { passKeys } from '#/query-key-factory'

type GetPassResponse = {
  pass: Pass
}

const getPass = async (id: string) =>
  (
    await axios.get<GetPassResponse>(`/pass/v2/${id}`, {
      params: { populateUser: true },
    })
  ).data

const useGetPass = (id: string) =>
  useQuery(passKeys.detail(id), async () => getPass(id), {
    enabled: !!id,
    select: data => data?.pass,
  })

export default useGetPass
