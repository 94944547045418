/**
 * @file LoginForm
 * @author Alwyn Tan
 */

import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import styled from 'styled-components'
import Button from '#/components/atoms/Button'
import Input from '#/components/atoms/Input'
import Text from '#/components/atoms/Text'
import { Purple } from '#/constants/colors'
import useLogin from '#/query/auth/useLogin'
import useRequestLogin from '#/query/auth/useRequestLogin'

const Container = styled(motion.form)`
  margin-top: 4px;
  position: relative;
`

const ExtraButtons = styled.div`
  position: absolute;
  width: 100%;

  > * {
    padding-top: 20px;
    text-align: center;
    width: 100%;
    color: ${Purple};
  }
`

const ResendText = styled(Text)<{ disabled: boolean }>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`

type FormValues = {
  code: string
}

type Props = {
  email: string
  onReset: () => void
}

const LoginForm = ({ email, onReset = () => {} }: Props) => {
  const [timeLeft, setTimeLeft] = useState(30)
  const { control, handleSubmit } = useForm<FormValues>({ mode: 'onChange' })
  const requestLoginMutation = useRequestLogin()
  const loginMutation = useLogin()

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(v => {
        if (v > 0) return v - 1
        return v
      })
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (requestLoginMutation.isSuccess) {
      setTimeLeft(30)
      const interval = setInterval(() => {
        setTimeLeft(v => {
          if (v > 0) return v - 1
          return v
        })
      }, 1000)

      return () => {
        clearInterval(interval)
      }
    }

    return () => {}
  }, [requestLoginMutation.isSuccess])

  const onSubmit: SubmitHandler<FormValues> = ({ code }) => {
    loginMutation.mutate({ email, code })
  }

  const handleResendClick = () => {
    requestLoginMutation.mutate(email)
  }

  return (
    <Container
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 100 }}
      transition={{ type: 'spring', duration: 0.5, bounce: 0.1 }}
    >
      <Text type="h4">Enter Your Code</Text>
      <Text style={{ margin: '4px 0 16px 0', opacity: 0.5 }}>
        Please enter the one time code (OTP) sent to {email}
      </Text>
      <Input
        control={control}
        inputProps={{ pattern: '\\d*', autoFocus: true }}
        name="code"
        placeholder="Enter code"
      />
      <Button
        size="medium"
        onClick={handleSubmit(onSubmit)}
        style={{ marginTop: 10 }}
      >
        Next
      </Button>
      <ExtraButtons>
        <ResendText
          type="button2"
          onClick={handleResendClick}
          disabled={timeLeft > 0}
        >
          Resend Code{timeLeft > 0 ? ` (${timeLeft})` : ''}
        </ResendText>
        <Text type="button2" onClick={onReset}>
          Sign into a different account
        </Text>
      </ExtraButtons>
    </Container>
  )
}

export default LoginForm
