/**
 * @file Date range component for available STL Cards
 * @author Alwyn Tan
 */

import dayjs from 'dayjs'
import React from 'react'
import styled from 'styled-components'
import Text from '#/components/atoms/Text'
import { DarkerGray } from '#/constants/colors'

const Container = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background: ${DarkerGray};
  border-radius: 10px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
`

const DateContainer = styled.div`
  > * {
    text-align: center;
  }

  > *:last-child {
    padding-top: 6px;
  }
`

type Props = {
  dateRange: [Date, Date]
}

const AvailableSTLCardDateRange = ({ dateRange }: Props) => {
  const dayjs1 = dayjs(dateRange[0]).tz()
  const dayjs2 = dayjs(dateRange[1]).tz()

  const isSameDay = dayjs1.isSame(dayjs2, 'day')

  return (
    <Container>
      <DateContainer>
        <Text type="h3">{dayjs1.format('D')}</Text>
        <Text type="h6">{dayjs1.format('MMM').toUpperCase()}</Text>
      </DateContainer>
      {!isSameDay && (
        <>
          <Text type="h4" style={{ padding: '0 10px' }}>
            -
          </Text>
          <DateContainer>
            <Text type="h3">{dayjs2.format('D')}</Text>
            <Text type="h6">{dayjs2.format('MMM').toUpperCase()}</Text>
          </DateContainer>
        </>
      )}
    </Container>
  )
}

export default AvailableSTLCardDateRange
