import { DefaultTheme } from 'styled-components'
import { OffBlack, Red } from '#/constants/colors'

declare module 'styled-components' {
  export interface DefaultTheme {
    Background: string
    Text: string
    Error: string
  }
}

const theme: DefaultTheme = {
  Background: OffBlack,
  Text: 'white',
  Error: Red,
}

export default theme
