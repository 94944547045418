/**
 * @file Skip the line list with active and past lists
 * @author Alwyn Tan
 */

import dayjs from 'dayjs'
import React, { useLayoutEffect } from 'react'
import styled from 'styled-components'
import InfiniteScroll from '#/components/molecules/InfiniteScroll'
import TabNav from '#/components/molecules/TabNav'
import STLCard from '#/components/organisms/STL/STLCard'
import { useHeader } from '#/contexts/header-context'
import useCurrentPartner from '#/query/auth/useCurrentPartner'
import useGetSTLs from '#/query/stl/useGetSTLs'

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 0;
`

const STLList = () => {
  const { data: partner } = useCurrentPartner()
  const { setHeaderTitle, resetHeader } = useHeader()

  useLayoutEffect(() => {
    setHeaderTitle('Guest Lists')
    return resetHeader
  }, [resetHeader, setHeaderTitle])

  return (
    <Container>
      <TabNav
        titles={['Active', 'Past']}
        tabStyle={{ paddingLeft: 16, paddingRight: 16 }}
      >
        <InfiniteScroll
          infiniteQueryHook={useGetSTLs}
          infiniteQueryHookParams={[
            {
              partner: partner?.partners[0],
              nextAvailableEndDate: `gte:${dayjs().toISOString()}`,
              sortBy: ['-nextAvailableStartDate'],
            },
          ]}
          style={{ paddingLeft: 16, paddingRight: 16 }}
        >
          {stl => <STLCard stl={stl} />}
        </InfiniteScroll>
        <InfiniteScroll
          infiniteQueryHook={useGetSTLs}
          infiniteQueryHookParams={[
            {
              partner: partner?.partners[0],
              nextAvailableEndDate: `exists:${false}`,
            },
          ]}
          style={{ paddingLeft: 16, paddingRight: 16 }}
        >
          {stl => <STLCard stl={stl} />}
        </InfiniteScroll>
      </TabNav>
    </Container>
  )
}

export default STLList
