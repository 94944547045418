/**
 * @file Entry page
 * @author Alwyn Tan
 */

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import axios from 'axios'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Toaster } from 'react-hot-toast'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import App from '#/App'
import QRScanner from '#/components/organisms/QR/QRScanner'
import { currentPartnerLoader } from '#/query/auth/useCurrentPartner'
import reportWebVitals from '#/reportWebVitals'
import GlobalStyle from '#/styles/globalStyle'
import theme from '#/styles/theme'
import '#/styles/fonts.css'
import '#/index.css'
import STLGuestList from './components/organisms/STL/STLGuestList'
import STLList from './components/organisms/STL/STLList'
import { getSTLLoader } from './query/stl/useGetSTL'

dayjs.extend(utc)
dayjs.extend(timezone)

dayjs.tz.setDefault('America/New_York')

const queryClient = new QueryClient()

// Setup global axios here - might want to pull it out to a separate file
/* setup all the axios defaults */
axios.defaults.baseURL = '/api'

// interceptor to auto silent login when initializing
axios.interceptors.request.use(async req => {
  const hasRefreshToken = document.cookie.indexOf('p_rtExists=') !== -1
  const isLoginRequest =
    req?.url?.endsWith('/partner/user/silent-login') ||
    req?.url?.endsWith('/partner/user/login')

  if (
    !axios.defaults.headers.common.Authorization &&
    hasRefreshToken &&
    !isLoginRequest
  ) {
    const { accessToken } = (
      await axios.post(
        `/partner/user/silent-login`,
        {},
        { withCredentials: true }
      )
    ).data

    if (accessToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
      req.headers.Authorization = `Bearer ${accessToken}`
    }
  }

  return req
})

// interceptor to auto retry login accessToken fails
axios.interceptors.response.use(null, async error => {
  const originalRequest = error.config

  if (
    originalRequest?.url !== '/partner/user/silent-login' &&
    originalRequest?.url !== '/partner/user/login' && // extra precaution to not retry logins
    !originalRequest?.retried &&
    error.response?.status === 401
  ) {
    delete axios.defaults.headers.common.Authorization
    originalRequest.retried = true

    const { accessToken } = (
      await axios.post(
        `/partner/user/silent-login`,
        {},
        { withCredentials: true }
      )
    ).data

    if (accessToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
      originalRequest.headers.Authorization = `Bearer ${accessToken}`
    }

    return axios(originalRequest)
  }

  // convert the error into something we'd like
  return Promise.reject(
    new Error(error.response.data?.error?.message || error.message)
  )
})

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    loader: currentPartnerLoader(queryClient),
    children: [
      {
        element: <QRScanner />,
        path: 'scan',
      },
      {
        element: <STLList />,
        path: 'stl',
      },
      {
        element: <STLGuestList />,
        path: 'stl/:stlID',
        loader: getSTLLoader(queryClient),
      },
      {
        index: true,
        element: <Navigate to="/scan" />,
      },
    ],
  },
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
      <Toaster
        toastOptions={{
          style: {
            boxShadow: '0px 2px 8px #000000',
            backgroundColor: '#262627',
            borderRadius: 100,
            color: 'white',
            fontFamily: 'Inter',
            zIndex: 99999,
          },
        }}
      />
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
