/**
 * @file Guest Row item for the passes
 * @author Alwyn Tan
 */

import { useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import React from 'react'
import { Check } from 'react-feather'
import styled from 'styled-components'
import ProfilePicture from '#/components/atoms/ProfilePicture'
import Text from '#/components/atoms/Text'
import useCheckinPass from '#/query/pass/useCheckinPass'
import { passKeys } from '#/query-key-factory'

const Container = styled.div`
  display: flex;
  max-width: 500px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`

const Button = styled.div`
  background: #ffffff1a;
  padding: 8px 16px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: white;
  border-radius: 100px;
`

const CheckinStatus = styled.div`
  display: flex;
  opacity: 0.5;
  align-items: center;
`

type Props = {
  pass: Pass
}

const STLGuestRowItem = ({ pass }: Props) => {
  const checkinPassMutation = useCheckinPass()
  const queryClient = useQueryClient()

  if (!pass) return null

  const handleCheckin = async () => {
    const { pass: newPass } = await checkinPassMutation.mutateAsync(pass.id)

    // hacky way to replace data now...
    queryClient.setQueryData(
      passKeys.list({
        // @ts-ignore
        stlID: pass?.meta?.stlID,
        populate: ['user'],
      }),
      (oldData: any) => ({
        ...oldData,
        pages: oldData.pages.map((page: any) => ({
          ...page,
          passes: page?.passes?.map((p: Pass) =>
            p.id === pass.id ? { ...p, checkedIn: newPass.checkedIn } : p
          ),
        })),
      })
    )
  }

  const user = pass.user as User

  return (
    <Container>
      <ProfilePicture user={user} size={32} />
      <Text style={{ paddingLeft: 10, paddingRight: 10, marginRight: 'auto' }}>
        {user.name}
      </Text>
      {pass.checkedIn ? (
        <CheckinStatus>
          <Text type="p2" style={{ paddingRight: 5 }}>
            {dayjs(pass.checkedIn.date).format('MMM DD, h:mma')}
          </Text>
          <Check size={16} color="white" />
        </CheckinStatus>
      ) : (
        <Button onClick={handleCheckin}>Check in</Button>
      )}
    </Container>
  )
}

export default STLGuestRowItem
