/**
 * @file Logout Hook
 * @author Alwyn Tan
 */

import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { partnerKeys } from '#/query-key-factory'

const postLogout = async () =>
  (await axios.post('/partner/user/logout', {}, { withCredentials: true })).data

const useLogout = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async () => {
      await postLogout()
      return {}
    },
    {
      onSuccess: () => {
        delete axios.defaults.headers.common.Authorization
        queryClient.invalidateQueries(partnerKeys.me())

        // todo: build in a more graceful reset in the future
        window?.location.reload()
        // window.analytics.reset()
      },
    }
  )
}

export default useLogout
