/**
 * @file Header
 * @author Alwyn Tan
 */

import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'
import { Menu, X } from 'react-feather'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Text from '#/components/atoms/Text'
import { OffBlack, Red } from '#/constants/colors'
import { useHeader } from '#/contexts/header-context'
import useCurrentPartner from '#/query/auth/useCurrentPartner'
import useLogout from '#/query/auth/useLogout'

const Container = styled.div`
  z-index: 999;

  /* only svgs under container are icons */
  svg {
    cursor: pointer;
  }

  * {
    user-select: none;
  }
`

const TitleRow = styled.div`
  display: flex;
  padding: 24px 24px 16px 24px;

  > h5 {
    padding-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const HeaderMenuIconContainer = styled.div`
  padding-right: 16px;
`

const HeaderImage = styled.div`
  height: 25px;
  width: 25px;
  background: url(${({ src }: { src: string }) => src});
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border-radius: 8px;
`

const MenuOverlay = styled(motion.div)`
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${OffBlack};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  box-shadow: 4px 0px 16px #00000080;
  padding: 24px;
`

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > * {
    padding: 20px;
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 1.2rem;
    margin: 0;
    color: ${({ theme }) => theme.Text};
  }
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10vh;

  > * {
    padding: 6px;
  }
`

const Backdrop = styled(motion.div)`
  background: #000000bf;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const Header = () => {
  const [showMenu, setShowMenu] = useState(false)
  const logoutMutation = useLogout()
  const { data: partner } = useCurrentPartner()
  const { headerStyle, headerTitle, headerImage } = useHeader()

  const handleLogoutClick = () => {
    logoutMutation.mutate()
  }

  const handleCloseMenu = () => {
    setShowMenu(false)
  }

  return (
    <Container>
      <TitleRow style={headerStyle}>
        <HeaderMenuIconContainer>
          <Menu color="white" onClick={() => setShowMenu(true)} />
        </HeaderMenuIconContainer>
        {headerImage && <HeaderImage src={headerImage} />}
        <Text type="h5">{headerTitle}</Text>
      </TitleRow>
      <AnimatePresence>
        {showMenu && (
          <>
            <Backdrop
              onClick={handleCloseMenu}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ type: 'spring', duration: 0.3, bounce: 0.05 }}
            />
            <MenuOverlay
              initial={{ x: '-100%' }}
              animate={{ x: 0 }}
              exit={{ x: '-100%' }}
              transition={{ type: 'spring', duration: 0.3, bounce: 0.05 }}
            >
              <X
                color="white"
                onClick={() => setShowMenu(false)}
                style={{ marginRight: 'auto' }}
              />
              <Content>
                <Link to="/scan" onClick={handleCloseMenu}>
                  Scan Passes
                </Link>
                <Link to="/stl" onClick={handleCloseMenu}>
                  View Guest Lists
                </Link>
              </Content>
              <Footer>
                <Text>
                  <span style={{ opacity: 0.5 }}>Logged in as</span>{' '}
                  {partner?.name}
                </Text>
                <Text
                  type="button2"
                  style={{ color: Red }}
                  onClick={handleLogoutClick}
                >
                  Logout
                </Text>
              </Footer>
            </MenuOverlay>
          </>
        )}
      </AnimatePresence>
    </Container>
  )
}

export default Header
