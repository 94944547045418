/**
 * @file App
 * @author Alwyn Tan
 */

import React from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import AuthPage from '#/components/pages/AuthPage'
import useCurrentPartner from '#/query/auth/useCurrentPartner'
import Header from './components/molecules/Header'
import HeaderProvider from './contexts/header-context'

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const App = () => {
  const {
    data: partner,
    isLoading,
    isFetched,
  } = useCurrentPartner({ retry: 0 })

  return (
    <Container>
      {isFetched && !isLoading && !partner ? (
        <AuthPage />
      ) : (
        <HeaderProvider>
          <Header />
          <Outlet />
        </HeaderProvider>
      )}
    </Container>
  )
}

export default App
