/**
 * @file Context for authentication related triggers
 * @author Alwyn Tan
 */

import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

type THeaderContext = {
  image: string
  setImage: React.Dispatch<React.SetStateAction<string>>
  style: object
  setStyle: React.Dispatch<React.SetStateAction<{}>>
  title: string
  setTitle: React.Dispatch<React.SetStateAction<string>>
}

const HeaderContext = createContext<THeaderContext>({
  image: '',
  setImage: () => {},
  style: {},
  setStyle: () => {},
  title: '',
  setTitle: () => {},
})

const useHeader = () => {
  const context = useContext(HeaderContext)
  if (context === undefined)
    throw new Error('useHeaderStyles was called outside of its Provider')

  const { image, setImage, style, setStyle, title, setTitle } = context

  const resetHeader = useCallback(() => {
    setImage('')
    setStyle({})
    setTitle('')
  }, [setImage, setStyle, setTitle])

  return {
    headerImage: image,
    setHeaderImage: setImage,
    headerStyle: style,
    headerTitle: title,
    setHeaderStyle: setStyle,
    setHeaderTitle: setTitle,
    resetHeader,
  }
}

const HeaderProvider = ({ children }: { children: React.ReactNode }) => {
  const [image, setImage] = useState('')
  const [style, setStyle] = useState({})
  const [title, setTitle] = useState('')
  const value = useMemo(
    () => ({ image, setImage, style, setStyle, title, setTitle }),
    [image, style, title]
  )

  return (
    <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
  )
}

export default HeaderProvider
export { useHeader }
