/**
 * @file Query to get passes
 * @author Alwyn Tan
 */

import { useInfiniteQuery } from '@tanstack/react-query'
import axios from 'axios'
import { passKeys } from '#/query-key-factory'

type GetPassesResponse = {
  passes: Pass[]
  canLoadMore: boolean
}

type GetPassesParams = {
  stlID: string
  populate: string[]
  sortBy: string[]
}

type PageParam = {
  prevID: string
}

const getPasses = async (params: GetPassesParams, pageParam: PageParam) =>
  (
    await axios.get<GetPassesResponse>('/pass/v2', {
      params: { ...pageParam, ...params, status: ['CONFIRMED'] },
    })
  ).data

const useGetPasses = (params: GetPassesParams) =>
  useInfiniteQuery(
    passKeys.list(params),
    async ({ pageParam }) => getPasses(params, pageParam),
    {
      // @ts-ignore
      select: data =>
        // @ts-ignore
        data?.pages?.reduce((acc, curr) => [...acc, ...curr.passes], []),
      getNextPageParam: prevPage => {
        if (prevPage.canLoadMore) {
          const prevPass = prevPage.passes[(prevPage?.passes.length || 0) - 1]
          return { prevID: prevPass?.id }
        }
        return undefined
      },
    }
  )

export default useGetPasses
