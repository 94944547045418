/**
 * @file Auth Page
 * @author Alwyn Tan
 */

import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'
import styled from 'styled-components'

import discoman from '#/images/discoman.png'
import LoginForm from '../organisms/Auth/LoginForm'
import RequestLoginForm from '../organisms/Auth/RequestLoginForm'

const Page = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 24px;
`

const Container = styled.div`
  width: 100%;
  max-width: 380px;
`

const Discoman = styled(motion.div)`
  height: 50px;
  width: 50px;
  background: url(${({ src }: { src: string }) => src});
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
`

const AuthPage = () => {
  const [email, setEmail] = useState('')

  return (
    <Page>
      <Container>
        <Discoman
          src={discoman}
          layout
          transition={{ type: 'spring', duration: 0.5, bounce: 0.1 }}
        />
        <AnimatePresence>
          {!email ? (
            <RequestLoginForm onComplete={e => setEmail(e)} />
          ) : (
            <LoginForm email={email} onReset={() => setEmail('')} />
          )}
        </AnimatePresence>
      </Container>
      <div style={{ height: '30%' }} />
    </Page>
  )
}

export default AuthPage
