/**
 * @file Query to get STL
 * @author Alwyn Tan
 */

import { QueryClient, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { LoaderFunctionArgs } from 'react-router-dom'
import { stlKeys } from '#/query-key-factory'

type GetSTLResponse = {
  stl: STL
}

const getSTL = async (id: string) =>
  (await axios.get<GetSTLResponse>(`/stl/${id}`)).data

const useGetSTL = (id: string) =>
  useQuery(stlKeys.detail(id), async () => getSTL(id), {
    enabled: !!id,
    select: data => data?.stl,
  })

// to use in new react-router v6 api
const getSTLLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) =>
    queryClient.ensureQueryData({
      queryKey: stlKeys.detail(params?.stlID || ''),
      queryFn: async () => {
        let res = {}
        try {
          res = await getSTL(params?.stlID || '')
        } catch (e) {
          //
        }
        return res
      },
    })

export { getSTLLoader }
export default useGetSTL
