/**
 * @file All keys for react query
 * @author Alwyn Tan
 */

export const partnerKeys = {
  all: ['partner'],
  me: () => [...partnerKeys.all, 'me'],
  details: () => [...partnerKeys.all, 'detail'],
  detail: (id: string) => [...partnerKeys.details(), id],
}

export const passKeys = {
  all: ['pass'],
  lists: () => [...passKeys.all, 'list'],
  list: (params?: any) => [...passKeys.lists(), params],
  details: () => [...passKeys.all, 'detail'],
  detail: (id: string) => [...passKeys.details(), id],
}

export const stlKeys = {
  all: ['stl'],
  lists: () => [...stlKeys.all, 'list'],
  list: (params?: any) => [...stlKeys.lists(), params],
  details: () => [...stlKeys.all, 'detail'],
  detail: (id: string) => [...stlKeys.details(), id],
}
